export default class Service {
	_apiGoRest = process.env.REACT_APP_GO_REST_API
	_apiRest = process.env.REACT_APP_REST_API

	_apiLogin = process.env.REACT_APP_AUTHORIZATION

	// GET
	getResource = async (api, url) => {
		const res = await fetch(`${api}${url}`)
		if (!res.ok) {
			// eslint-disable-next-line no-useless-concat
			throw new Error(`Could not fetch ${url}` + `, received ${res.status}`)
		}
		return await res.json()
	}

	getCars = async () => {
		return await this.getResource(this._apiGoRest, `/public/web/rate/short`)
	}

	getDiff = async () => {
		return await this.getResource(this._apiGoRest, `/public/web/rate/diff`)
	}

	getMapCars = async () => {
		return await this.getResource(this._apiRest, `/public/v1/rental-objects`)
	}

	getMapGeo = async () => {
		return await this.getResource(this._apiGoRest, `/public/web/geo/list`)
	}

	getNews = async ({
		id = null,
		limit = null,
		offset = null,
		lng = 'lv',
	} = {}) => {
		if (id) {
			return await this.getResource(
				this._apiGoRest,
				`/public/web/article?_id=${id}&_locale=${lng}`
			)
		} else if (limit && offset) {
			return await this.getResource(
				this._apiGoRest,
				`/public/web/article?_locale=${lng}&_offset=${offset}&_limit=${limit}&_order=desc`
			)
		} else if (limit) {
			return await this.getResource(
				this._apiGoRest,
				`/public/web/article?_locale=${lng}&_offset=0&_limit=${limit}&_order=desc`
			)
		} else if (offset) {
			return await this.getResource(
				this._apiGoRest,
				`/public/web/article?_locale=${lng}&_offset=${offset}&_limit=10&_order=desc`
			)
		} else {
			return await this.getResource(
				this._apiGoRest,
				`/public/web/article?_locale=${lng}&_offset=0&_limit=-1&_order=desc`
			)
		}
	}

	getMobileArticle = async (lng, id) => {
		return await this.getResource(
			this._apiGoRest,
			`/public/web/article?_id=${id}&_locale=${lng}`
		)
	}

	getBonuses = async lng => {
		return await this.getResource(this._apiGoRest, `/public/web/level/${lng}`)
	}

	// POST
	postResource = async (url, data, token = false) => {
		let headers
		if (token) {
			headers = new Headers({
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/x-www-form-urlencoded',
				Accept: 'application/json',
			})
		} else {
			headers = {
				'Content-Type': 'application/json;charset=utf-8',
				Accept: 'application/json',
			}
		}
		const res = await fetch(`${this._apiLogin}${url}`, {
			// const res = await fetch(`https://rest.carguru.online${url}`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(data),
		})
		if (!res.ok) {
			throw new Error(`Could not fetch`)
		}
		return await res.json()
	}

	postLogin = async data => {
		return await this.postResource('/authorization', data)
	}

	postPromo = async token => {
		return await this.postResource('/promo/generate', null, token)
	}
}
